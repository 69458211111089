<template>
  <!--begin::Quotation Internal Listing-->
  <v-container fluid>
    <v-container>
      <v-row>
        <v-col md="2">
          <v-select
            :disabled="dataLoading"
            :loading="dataLoading"
            :items="statusList"
            v-model="filter.status"
            hide-details
            flat
            solo
            dense
            label="Status"
            color="cyan"
            item-color="cyan"
            class="pt-0 mt-0"
            item-text="text"
            item-value="value"
            v-on:change="getAllQuotations"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.description"
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip :color="item.color" :text-color="item.textcolor">
                  <template v-if="item.value == 'all'">{{
                    item.all_quotations
                  }}</template>
                  <template v-else>
                    <template v-if="preQuotation">{{
                      item.pre_quotation_status_count
                    }}</template>
                    <template v-else>{{
                      item.quotation_status_count
                    }}</template>
                  </template>
                </v-chip>
              </v-list-item-action>
            </template>
          </v-select>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
            @keydown.enter="getAllQuotations"
            @keydown.tab="getAllQuotations"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                flat
                label="Dates Range"
                hide-details
                solo
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click:clear="clearFilter('dates')"
                clearable
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="formattedDate"
                color="cyan"
              ></v-text-field>
            </template>
            <v-date-picker
              color="cyan"
              range
              :disabled="dataLoading"
              :loading="dataLoading"
              v-on:change="getAllQuotations"
              v-model="dates"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="getAllQuotations"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <template v-if="type == 'project' && detail.status < 4">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-on:click="addQuotation()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add Quotation</span>
            </v-tooltip>
          </template>
          <template v-if="false && type == 'project' && detail.status < 4">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-on:click="open_package_dialog()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Create New Quotation</span>
            </v-tooltip>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable
      :columnCount="7"
      :dataLoading.sync="dataLoading"
      :rowData.sync="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">
              <template v-if="preQuotation">Quotation</template
              ><template v-else>Summary Report</template> #
            </th>
            <th class="simple-table-th" width="300">
              <template v-if="preQuotation">Quotation</template
              ><template v-else>Summary Report</template> Title
            </th>
            <th class="simple-table-th" width="300">Basic Details</th>
            <th class="simple-table-th" width="200">Dates</th>
            <th class="simple-table-th" width="200">Status</th>
            <th class="simple-table-th" width="200">Customer Info</th>
            <th class="simple-table-th" width="200">Amount</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length > 0">
            <tr
              link
              :key="index"
              v-for="(row, index) in dataRows"
              v-on:click="routeToDetail(row)"
            >
              <td class="simple-table-td">
                <Barcode
                  route="quotation.detail"
                  :barcode="row.barcode"
                  :id="row.id"
                ></Barcode>
              </td>
              <td class="simple-table-td">
                <div class="quotation-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis-two-line">
                    <b>Title: </b>
                    <ShowValue
                      :object="row"
                      object-key="quotation_title"
                      label="title"
                    ></ShowValue>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis-two-line">
                    <b>Site Address: </b>
                    <ShowValue
                      :object="row"
                      object-key="billing.property_address"
                      label="renovation address"
                    ></ShowValue>
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="quotation-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Reference: </b>
                    <ShowValue
                      :object="row"
                      object-key="reference"
                      label="reference"
                    ></ShowValue>
                  </p>
                  <p v-if="false" class="m-0 custom-nowrap-ellipsis">
                    <b>Sales representative: </b>
                    <template v-if="row.sales">{{ row.sales }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >No Sales representative</em
                      ></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Prepared by: </b>
                    <ShowValue
                      :object="row"
                      object-key="prepared_by"
                      label="prepared by"
                    ></ShowValue>
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="quotation-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis" v-if="row.date">
                    <b>Created: </b> {{ formatDate(row.date) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis" v-if="row.open_till">
                    <b>Open Till: </b> {{ formatDate(row.open_till) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis" v-if="row.sent_date">
                    <b>Sent: </b> {{ formatDate(row.sent_date) }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <CustomStatus
                  small
                  :status.sync="row.status"
                  endpoint="quotation/status"
                ></CustomStatus>
              </td>
              <td class="simple-table-td" width="100">
                <div class="quotation-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Name: </b>
                    {{
                      row.attention
                        ? row.attention
                        : row.contact_person
                        ? row.contact_person.display_name
                        : ""
                    }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Company: </b>
                    <ShowValue
                      :object="row"
                      object-key="customer.display_name"
                      label="company"
                    ></ShowValue>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Email: </b>
                    <ShowValue
                      :object="row"
                      object-key="contact_person.primary_email"
                      label="email"
                    ></ShowValue>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Phone: </b>
                    <ShowValue
                      :object="row"
                      object-key="contact_person.primary_phone"
                      label="phone"
                    ></ShowValue>
                  </p>
                </div>
              </td>
              <td
                class="simple-table-td"
                width="100"
                style="position: relative"
              >
                <div class="quotation-listing-amount">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Profit: </b>
                    {{ $accountingJS.formatMoney(row.total_profit) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Total: </b>
                    {{ $accountingJS.formatMoney(row.total) }}
                  </p>
                </div>
                <div class="qt-del-btn">
                  <v-btn
                    v-on:click.prevent.stop="delete_row(row, index)"
                    class="custom-bold-button"
                    color="red lighten-1"
                    icon
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="7">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no summary report at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <Dialog :common-dialog="selectPackageDialog">
      <template v-slot:title>
        <v-layout>
          <v-flex> Select Package </v-flex>
          <v-flex class="text-right">
            <v-btn icon v-on:click="selectPackageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </template>
      <template v-slot:body>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="position: static"
        >
          <v-container fluid class="pt-0">
            <v-text-field
              ref="search-customer"
              v-model.trim="search_text"
              :loading="search_loader"
              autocomplete="off"
              class="mx-2 mx-md-4 rounded-lg"
              dense
              placeholder="Start Typing then press enter"
              hide-details
              solo
              @keydown.esc="get_quotation_packages()"
              @keydown.enter="get_quotation_packages()"
              @keydown.tab="get_quotation_packages()"
            >
              <template #prepend-inner>
                <v-icon color="grey" class="ml-1 mr-2"> mdi-magnify </v-icon>
              </template>
            </v-text-field>
            <br />
            <v-list class="mx-2 mx-md-4" subheader three-line>
              <v-skeleton-loader
                v-if="search_loader"
                class="custom-skeleton"
                type="list-item-two-line"
              >
              </v-skeleton-loader>
              <template v-else>
                <template v-if="quotation_packages.length > 0">
                  <v-list-item
                    v-for="(data, index) in quotation_packages"
                    v-on:click="select_package(data)"
                    :key="index"
                    link
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        class="font-weight-500 font-size-16 pt-2"
                      >
                        <Barcode :barcode="data.barcode" small></Barcode>
                        <p class="my-1">{{ data.quotation_title }}</p>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="font-weight-500 font-size-14"
                      >
                        {{ data.raw_property.property_address }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-else>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-500 font-size-16"
                        >No Package(s) Found under This Project.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </template>
            </v-list>
          </v-container>
        </perfect-scrollbar>
      </template>
    </Dialog>
    <Dialog :common-dialog="selectParentDialog" :dialog-width="dialogWidth">
      <template v-slot:title>
        <v-layout>
          <v-flex>
            Select <template v-if="preQuotation">Quotations</template
            ><template v-else>Summary Reports</template>
          </v-flex>
          <v-flex class="text-right">
            <v-btn
              :disabled="parentLoading"
              class="mx-2 custom-bold-button"
              v-on:click="
                pt_quote = 0;
                dQuotation = [];
                selectParentDialog = false;
              "
            >
              Close
            </v-btn>
            <v-btn
              v-if="dQuotation.length"
              :loading="parentLoading"
              :disabled="!dSelected.length || parentLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-on:click="saveParent()"
            >
              Save
            </v-btn>
          </v-flex>
        </v-layout>
      </template>
      <template v-slot:body>
        <div>
          <v-layout
            v-if="!preQuotation"
            style="max-width: 500px"
            class="align-center justify-center"
          >
            <v-flex md3>
              <label for="quotation">Quotation</label>
            </v-flex>
            <v-flex md9>
              <v-autocomplete
                id="quotation"
                dense
                filled
                flat
                hide-details
                solo
                color="cyan"
                placeholder="Select Quotation"
                :disabled="parentLoading"
                :loading="parentLoading"
                :items="dSQuotation"
                item-text="quotation_title"
                item-value="id"
                v-model="pt_quote"
                v-on:change="getQuotationVo($event)"
              >
                <template v-slot:selection="{ item, index }">
                  {{ item.barcode }} - {{ item.quotation_title }}
                </template>
                <template v-slot:item="{ item, index }">
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ item.barcode }} -
                      {{ item.quotation_title }}</v-list-item-title
                    >
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-flex>
          </v-layout>
          <div class="custom-listing-table mt-4">
            <div class="v-data-table simple-table table-height theme--light">
              <div class="v-data-table__wrapper" style="overflow: auto">
                <table width="100%">
                  <thead>
                    <tr>
                      <th class="simple-table-th"></th>
                      <th class="simple-table-th">
                        <template v-if="preQuotation">Quotation #</template
                        ><template v-else>Summary Report #</template>
                      </th>
                      <th class="simple-table-th">Basic Details</th>
                      <th class="simple-table-th">Dates</th>
                      <th class="simple-table-th">Status</th>
                    </tr>
                  </thead>
                  <tbody v-if="dQuotation.length">
                    <tr v-for="(data, index) in dQuotation" :key="index">
                      <td class="simple-table-td">
                        <v-checkbox
                          v-model="dSelected"
                          multiple
                          color="cyan"
                          :value="data.id"
                          :disabled="parentLoading"
                        ></v-checkbox>
                      </td>
                      <td class="simple-table-td">
                        <Barcode :barcode="data.barcode"></Barcode>
                      </td>
                      <td class="simple-table-td">
                        <div class="lead-listing">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Title: </b>
                            <ShowValue
                              :object="data"
                              object-key="quotation_title"
                              label="title"
                            ></ShowValue>
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Reference: </b>
                            <ShowValue
                              :object="data"
                              object-key="reference"
                              label="reference"
                            ></ShowValue>
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Prepared by: </b>
                            <ShowValue
                              :object="data"
                              object-key="prepared_by"
                              label="prepared by"
                            ></ShowValue>
                          </p>
                        </div>
                      </td>
                      <td class="simple-table-td">
                        <div class="lead-listing">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Quote Date: </b> {{ formatDate(data.date) }}
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Open Till: </b>
                            {{ formatDate(data.open_till) }}
                          </p>
                          <p
                            class="m-0 custom-nowrap-ellipsis"
                            v-if="data.sent_date"
                          >
                            <b>Sent: </b> {{ formatDate(data.sent_date) }}
                          </p>
                        </div>
                      </td>
                      <td class="simple-table-td">
                        <CustomStatus
                          endpoint="pre-quotation/status"
                          small
                          :status.sync="data.status"
                        ></CustomStatus>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot v-else>
                    <tr>
                      <td colspan="5">
                        <p
                          class="m-0 row-not-found text-center font-weight-500 font-size-16"
                        >
                          <img
                            :src="$assetURL('media/error/empty.png')"
                            class="row-not-found-image"
                          />
                          Uhh... There are no
                          <template v-if="preQuotation">quotations</template
                          ><template v-else>quotation vos</template> at the
                          moment.
                        </p>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Dialog>
    <Dialog :common-dialog="del_confirm">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <div>
          <p
            class="font-size-18 font-weight-500"
            style="line-height: 1.5; color: #000000de"
          >
            Are you sure, you want to remove
            <b
              >Quotation # {{ del_row.barcode }} -
              {{ del_row.quotation_title }}</b
            >
            from <b>Project # {{ detail.barcode }} - {{ detail.name }}</b
            >?
          </p>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          v-on:click="remove_row()"
          class="custom-bold-button"
          color="cyan white--text"
          >Yes ! Remove</v-btn
        >
        <v-btn v-on:click="del_confirm = false" class="custom-bold-button"
          >No ! Cancel</v-btn
        >
      </template>
    </Dialog>
  </v-container>
  <!--end::Quotation Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import { QUERY, PATCH, DELETE } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import ShowValue from "@/view/pages/partials/Show-Value.vue";
import ObjectPath from "object-path";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "quotation-internal-list",
  data() {
    return {
      parent: 0,
      customer: 0,
      customerPerson: 0,
      customerBilling: 0,
      dates: [],
      statusList: [],
      dataRows: [],
      search_text: null,
      search_loader: false,
      dataLoading: true,
      selectPackageDialog: false,
      selectParentDialog: false,
      parentLoading: false,
      momentObject: moment,
      quotation_packages: [],
      customerList: [],
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
      dSelected: [],
      dQuotation: [],
      dSQuotation: [],
      dSearch: null,
      removeLoading: false,
      del_confirm: false,
      del_row: {},
      del_index: 0,
      pt_quote: 0,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "product",
    },
    preQuotation: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          if (this.type == "customer") {
            this.customer = this.parent;
            if (this.detail && this.detail.address) {
              let customerAddress = this.lodash.filter(
                this.detail.address,
                function (data) {
                  return data.type === 2;
                }
              );
              if (customerAddress) {
                this.customerBilling = customerAddress[0].id;
              }
            }
          }
          if (this.lodash.isEmpty(param.customer) === false) {
            this.customer = this.lodash.toSafeInteger(param.customer.customer);
            if (this.lodash.isEmpty(param.customer_billing) === false) {
              this.customerBilling = param.customer_billing.id;
            }
          }
          this.getAllQuotations();
        }
      },
    },
  },
  components: {
    Barcode,
    ShowValue,
    CustomStatus,
    Dialog,
    ListingTable,
  },
  methods: {
    delete_row(row, index) {
      this.del_row = row;
      this.del_index = index;
      this.del_confirm = true;
    },
    routeToDetail(row) {
      let detail_route = "quotation.detail";
      if (this.preQuotation) {
        detail_route = "pre.quotation.detail";
      }

      this.$router.push(
        this.getDefaultRoute(detail_route, {
          params: {
            id: row.id,
          },
        })
      );
    },
    remove_row() {
      this.removeLoading = true;
      let url = `project/${this.detail.id}/quotation/${this.del_row.id}`;
      if (this.preQuotation) {
        url = `project/${this.detail.id}/pre-quotation/${this.del_row.id}`;
      }
      this.$store
        .dispatch(DELETE, { url })
        .then(() => {
          this.dataRows.splice(this.del_index, 1);
          this.del_row = {};
          this.del_index = 0;
          this.del_confirm = false;
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.removeLoading = false;
        });
    },
    getQuotationVo(param) {
      this.parentLoading = false;
      this.$store
        .dispatch(QUERY, {
          url: "quotation",
          data: {
            status: "accepted",
            search: this.dSearch,
            type: "quotation",
            type_id: param,
            "is-project-selection": 1,
          },
        })
        .then((output) => {
          this.dQuotation = ObjectPath.get(output, "data.rows", []);
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.parentLoading = false;
        });
    },
    saveParent() {
      this.parentLoading = true;

      let type = "quotation";

      if (this.preQuotation) {
        type = "pre-quotation";
      }

      let parentId = ObjectPath.get(this.detail, "id", 0);

      this.$store
        .dispatch(PATCH, {
          url: `project/${parentId}/${type}`,
          data: { quotation: this.dSelected },
        })
        .then(() => {
          this.pt_quote = 0;
          this.dQuotation = [];
          this.dSelected = [];
          this.selectParentDialog = false;
          this.getAllQuotations();
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.parentLoading = false;
        });
    },
    addQuotation() {
      this.parentLoading = true;

      let url = "quotation";

      if (this.preQuotation) {
        url = "pre-quotation";
      }

      if (this.preQuotation) {
        this.$store
          .dispatch(QUERY, {
            url,
            data: {
              status: "accepted",
              search: this.dSearch,
              customer: ObjectPath.get(this.detail, "customer.customer", 0),
              "is-project-selection": 1,
            },
          })
          .then((output) => {
            this.dQuotation = ObjectPath.get(output, "data.rows", []);
            this.selectParentDialog = true;
          })
          .catch((error) => {
            console.log({ error });
          })
          .finally(() => {
            this.parentLoading = false;
          });
      } else {
        this.$store
          .dispatch(QUERY, {
            url: "pre-quotation",
            data: {
              status: "accepted",
              search: this.dSearch,
              type: "project",
              type_id: ObjectPath.get(this.detail, "id", 0),
              customer: ObjectPath.get(this.detail, "customer.customer", 0),
            },
          })
          .then((output) => {
            this.dSQuotation = ObjectPath.get(output, "data.rows", []);
            this.selectParentDialog = true;
          })
          .catch((error) => {
            console.log({ error });
          })
          .finally(() => {
            this.parentLoading = false;
          });
      }
    },
    resetAll() {
      this.customerPerson = 0;
      this.customerBilling = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.customerPersonDialog = false;
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkQuotation();
      if (!this.customerBilling || this.customerBilling <= 0) {
        this.resetAll();
      }
    },
    selectCustomerProperty(param) {
      this.customerBilling = param;
      this.closeDialog();
      this.checkQuotation();
    },
    open_package_dialog() {
      this.get_quotation_packages();
      this.$nextTick(() => {
        this.selectPackageDialog = true;
      });
    },
    select_package(row) {
      this.selectPackageDialog = false;
      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("quotation.create", {
            query: {
              package: ObjectPath.get(row, "id", 0),
              customer: ObjectPath.get(row, "customer", 0),
              contact_person: ObjectPath.get(row, "contact_person", 0),
              billing: ObjectPath.get(row, "billing", 0),
              property: ObjectPath.get(row, "property", 0),
            },
          })
        );
      });
    },
    get_quotation_packages() {
      this.search_loader = true;
      this.$store
        .dispatch(QUERY, {
          url: "quotation/packages",
          data: { search: this.search_text, project: this.parent },
        })
        .then((response) => {
          this.quotation_packages = ObjectPath.get(response, "data", []);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.search_loader = false;
        });
    },
    checkQuotation() {
      const _this = this;
      if (
        _this.customer > 0 &&
        _this.customerPerson > 0 &&
        _this.customerBilling > 0
      ) {
        let queryObject = new Object({
          customer: _this.customer,
          contact_person: _this.customerPerson,
          billing: _this.customerBilling,
        });

        queryObject[_this.type] = _this.parent;

        _this.$router.push(
          _this.getDefaultRoute("quotation.create", {
            query: queryObject,
          })
        );
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllQuotations();
    },
    getAllQuotations() {
      const _this = this;

      let filter = {
        per_page: 10,
        current_page: 1,
        status: _this.filter.status || "all",
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
        type: _this.type,
        type_id: _this.parent,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        let url = "quotation";
        if (_this.preQuotation) {
          url = "pre-quotation";
        }
        _this.$store
          .dispatch(QUERY, { url, data: filter })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.statusList = data.status_list;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 70);
    },
  },
};
</script>
